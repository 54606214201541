export const projects = [
  {
    id: 16,
    name: 'House "EE4"',
    category: 'Exterior / Interior',
    architects: 'AQ Architecture',
    renderings: 'AQ Architecture',
    location: 'Drenas, Kosova',
    role: ['Interior', 'Exterior'],
    // 6 images
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'],
  },
  {
    id: 13,
    name: 'VILLA MIESS',
    category: 'Exterior / Interior',
    architects: 'AQ Architecture',
    renderings: 'AQ Architecture',
    location: 'Zurich, Switzerland',
    role: ['Interior', 'Exterior'],
    // 15 images
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg', '7.jpg', '8.jpg', '10.jpg', '11.jpg', '12.jpg', '13.jpg', '14.jpg', '15.jpg'],
  },
  {
    id: 14,
    name: 'INTERIOR AND LANDSCAPE RENOVATION',
    category: 'Exterior / Interior',
    architects: 'AQ Architecture',
    renderings: 'AQ Architecture',
    location: 'Wickrath, Monchengladbach, Germany',
    role: ['Interior', 'Exterior'],
    // 8 images
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg', '7.jpg'],
  },
  {
    id: 15,
    name: 'HOUSE "44"',
    category: 'Exterior / Interior',
    architects: 'AQ Architecture',
    renderings: 'AQ Architecture',
    location: 'Suhareke, Kosova',
    role: ['Interior', 'Exterior'],
    // 8 images
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg', '7.jpg', '8.jpg'],
  },
  {
    id: 1,
    name: 'Richterswil',
    category: 'Interior',
    architects: 'AQ Architecture',
    renderings: 'AQ Architecture',
    location: 'Richterswil, Switzerland',
    role: ['Interior'],
    // 6 images
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'],
  },
  {
    id: 2,
    name: 'Renovation',
    category: 'Interior and landscape design',
    architects: 'AQ Architecture',
    renderings: 'AQ Architecture',
    location: 'Wickrath, Monchengladbach, Germany',
    // 14 images
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg', '7.jpg', '9.jpg', '10.jpg', '11.jpg', '12.jpg', '13.jpg', '14.jpg'],
  },
  {
    id: 3,
    name: 'House "33"',
    category: 'Exterior',
    architects: 'AQ Architecture',
    renderings: 'AQ Architecture',
    location: 'Prizren, Kosova',
    // 7 images
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg', '7.jpg'],
  },
  {
    id: 4,
    name: 'Lense Store',
    category: 'Enterior / Interior',
    architects: 'AQ Architecture',
    renderings: 'AQ Architecture',
    location: 'Nurnberg, Germany',
    // 2 images
    images: ['1.jpg', '2.jpg'],
  },
  {
    id: 9,
    name: 'Living Room A1',
    category: 'Interior',
    architects: 'AQ Architecture',
    renderings: 'AQ Architecture',
    location: 'Prizren, Kosova',
    // 8 images
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg', '7.jpg', '8.jpg'],
  },
  {
    id: 7,
    name: 'Physio Clinic',
    category: 'Interior / Exterior',
    architects: 'AQ Architecture',
    renderings: 'AQ Architecture',
    location: 'Prizren, Kosova',
    // 11 images
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg', '7.jpg', '9.jpg', '10.jpg', '11.jpg'],
  },
  {
    id: 8,
    name: 'Mountain Villa',
    category: 'Exterior',
    architects: 'AQ Architecture',
    renderings: 'AQ Architecture',
    location: 'Prevall, Prizren, Kosova',
    // 3 images
    images: ['1.jpg', '2.jpg', '3.jpg'],
  },
  {
    id: 5,
    name: 'Living Room A3',
    category: 'Interior',
    architects: 'AQ Architecture',
    renderings: 'AQ Architecture',
    location: 'Suhareke, Kosova',
    // 3 images
    images: ['1.jpg', '2.jpg', '3.jpg'],
  },
];

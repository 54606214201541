import { React } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { projects } from '../../constants/projects';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div id="page">
      <Header />
      <div id="fh5co-work">
        <div className="container">
          <div className="row top-line">
            <div className="col-md-6 col-md-push-3 text-center intro">
              <h2>
                AQ Architecture
                <span className="fh5co-highlight">Everything you can imagine is real.</span>
              </h2>
            </div>
          </div>
          <div className="row">
            {projects &&
              projects.map((project) => (
                <div className="col-md-4 text-center ">
                  <Link to={`/project/${project.id}`} className="work">
                    <div className="work-grid" style={{ backgroundImage: `url(images/project-${project.id}.jpg)` }}>
                      <div className="inner">
                        <div className="desc">
                          <h3>{project.name}</h3>
                          <span className="cat">{project.category}</span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div id="fh5co-started hidden">
        <div className="container">
          <div className="row ">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
              <h2>Get Started</h2>
              <p>We bring to life your night and day dreams. Let's get started, everything starts from details.</p>
              <p>
                <a href="/contact" className="btn btn-primary">
                  Let's work together
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;

import { React } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

const Team = () => {
  return (
    <div id="page">
      <Header />
      <div id="fh5co-work">
        <div className="container">
          <div className="row top-line animate-box">
            <div className="col-md-6 col-md-push-3 text-center intro">
              <h2>
                AQ Architecture's Team <span className="fh5co-highlight">Everything you can imagine is real. </span>
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 text-center animate-box">
              <a className="work" href="/">
                <div className="work-grid" style={{ backgroundImage: "url('./images/albert.jpg')" }}>
                  <div className="inner">
                    <div className="desc">
                      <h3>Albert Qelaj</h3>
                      <span className="cat">Lead Architect</span>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-6 text-center animate-box">
              <a className="work" href="/">
                <div className="work-grid" style={{ backgroundImage: "url('./images/bestar.jpg')" }}>
                  <div className="inner">
                    <div className="desc">
                      <h3>Bestar Guraziu</h3>
                      <span className="cat">Exterior / Interior Designer </span>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div id="fh5co-started hidden">
          <div className="container">
            <div className="row animate-box">
              <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
                <h2>Get Started</h2>
                <p>We bring to life your night and day dreams. Let's get started, everything starts from details.</p>
                <p>
                  <a href="contact.php" className="btn btn-primary">
                    Let's work together
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Team;

import { React } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Slider from 'react-slick';
import { projects } from '../../constants/projects';

const Project = () => {
  let { id } = useParams();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
  };

  const project = projects.find((project) => project.id === id * 1) || false;
  console.log(project);
  return (
    <>
      <Header />
      <div className="row  project-details">
        <div className="col-md-6 col-md-offset-4  ">
          <h2>{project.name}</h2>
          <p>Architects | {project.architects}</p>
          <p>Renderings | {project.renderings}</p>
          <p>
            Location: <b>{project.location}</b>
          </p>
          {project.role && project.role.length > 0 && (
            <div className="role">
              <h3>Role</h3>
              <ul>{project.role && project.role.map((role, key) => <li key={key}>{role}</li>)}</ul>
            </div>
          )}
        </div>
      </div>
      <div style={{ marginBottom: '70px' }}>
        <Slider {...settings}>
          {project.images &&
            project.images.map((image, key) => (
              <div key={key}>
                <img className="projectImage" src={'/images/projects/' + project.id + '/' + image} alt={'Project ' + project.id} />
              </div>
            ))}
        </Slider>
      </div>

      <Footer />
    </>
  );
};

export default Project;

import { Link } from 'react-router-dom';

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer id="fh5co-footer" role="contentinfo">
        <div className="container">
          <div className="row copyright">
            <div className="col-md-12 text-center">
              <p>
                <small className="block">&copy; {currentYear} AQ Architecture. All Rights Reserved.</small>
                <small className="block">
                  Designed by
                  <Link to="https://www.aqarchitecture.com/"> AQ Architecture</Link>
                </small>
              </p>

              <ul className="fh5co-social-icons">
                <li>
                  <a href="https://www.instagram.com/aq_architecture/" target="_blank" rel="noreferrer">
                    <i className="icon-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/aqarchitecture" target="_blank" rel="noreferrer">
                    <i className="icon-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/in/albert-qelaj-aa6502162" target="_blank" rel="noreferrer">
                    <i className="icon-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;

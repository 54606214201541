import { React } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

const Contact = () => {
  return (
    <div id="page">
      <Header />
      <div id="fh5co-contact">
        <div class="container">
          <div class="row top-line animate-box">
            <div class="col-md-6 col-md-offset-3 col-md-push-2 text-left fh5co-heading">
              <h2>Say Hello</h2>
              <p>
                Do you have any questions about our projects or our site in general? Do you have any comments or ideas you would like to share with us? Please feel free to send us a message. We love to hear from our visitors and get their opinions!
                We are always looking for great new ideas to add to our site. So if you have a project you would like to share with others, let us know!
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8 col-md-offset-2">
              <div class="col-md-5 col-md-pull-1 animate-box">
                <div class="fh5co-contact-info">
                  <h3>Contact Information</h3>
                  <ul>
                    <li class="address">
                      Naim Frasheri nr:3 <br /> 22000 Prizren <br /> Kosovo
                    </li>
                    <li class="phone">
                      <a href="tel:+38344882291">+38344882291</a>
                    </li>
                    <li class="email">
                      <a href="mailto:info@aqarchitecture.com">info@aqarchitecture.com</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-7 animate-box">
                <h3>Get In Touch</h3>
                <form action="https://formsubmit.co/b471117112444626914bc922bbab68fd" method="post">
                  <div class="row form-group">
                    <div class="col-md-12">
                      <label for="full_name">Your Name</label>
                      <input type="text" name="full_name" id="full_name" class="form-control" />
                    </div>
                  </div>

                  <div class="row form-group">
                    <div class="col-md-12">
                      <label for="email">Email</label>
                      <input type="text" name="email" id="email" class="form-control" />
                    </div>
                  </div>

                  <div class="row form-group">
                    <div class="col-md-12">
                      <label for="telephone">Phone</label>
                      <input type="text" name="telephone" id="telephone" class="form-control" />
                    </div>
                  </div>

                  <div class="row form-group">
                    <div class="col-md-12">
                      <label for="message">Message</label>
                      <textarea name="message" id="message" cols="30" rows="10" class="form-control"></textarea>
                    </div>
                  </div>
                  <div class="form-group">
                    <input type="submit" value="Send Message" class="btn btn-primary" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;

import * as constants from './actionTypes';

const initialState = {
  active_page: '/index',
};

const rootReducer = (appstate = initialState, action) => {
  switch (action.type) {
    case constants.ACTIVE_PAGE:
      return { ...appstate, active_page: action.payload };
    default:
      return { ...appstate };
  }
};

export default rootReducer;

import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const mainMenu = [
  {
    url: '/index',
    name: 'Projects',
  },
  {
    url: '/team',
    name: 'Team',
  },
  {
    url: '/contact',
    name: 'Contact',
  },
];

function Header() {
  const active_page = useSelector((state) => state.active_page);

  const [currentActivePage, setCurrentActivePage] = useState({
    index: '',
    service: '',
    gallery: '',
    contact: '',
    impressum: '',
    privacy: '',
  });
  const [pageTitle, setPageTitle] = useState('');

  useEffect(() => {
    // disable all active page
    Object.keys(currentActivePage).forEach((key) => {
      currentActivePage[key] = '';
    });
    // Set active page
    setCurrentActivePage({ ...currentActivePage, [active_page]: 'current' });
    setPageTitle(mainMenu.find((item) => item.url === active_page).name);
  }, [active_page]);
  return (
    <>
      <Helmet>
        <title> {pageTitle} - AQ Architecture </title>
      </Helmet>
      <nav className="fh5co-nav" role="navigation">
        <div className="container">
          <div className="fh5co-top-logo">
            <div id="fh5co-logo">
              <Link to="/index">
                <img src="/images/logo.png" />
              </Link>
            </div>
          </div>
          <div className="fh5co-top-menu menu-1 text-right" style={{ height: '120px' }}>
            <ul style={{ paddingTop: '50px' }}>
              {mainMenu.map((item, index) => (
                <li key={index} className={currentActivePage[item.url]}>
                  <Link to={item.url}>{item.name}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
